<template>
  <div class="d-flex justify-center filter-hover">
    <div>
      <div class="border-on d-flex shadow-on radius-all">
        <div class="text-center px-4 py-2 hoverable" v-if="showrole">
          <div class="grey--text text--darken-1 line-1">Role</div>
          <div class="font-weight-bold">
            <v-menu offset-y v-if="Object.keys(rolelist).length > 1" open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <div class="filter-hide cursor-pointer" v-bind="attrs" v-on="on">{{(rolelist[selectedrole] || {}).name}}<v-icon class="">mdi-chevron-down</v-icon></div>
              </template>
              <perfect-scrollbar style="max-height: 300px">
                <v-list dense class="pa-0 border-left-default">
                  <template v-for="(v,k) in rolelist">
                    <v-list-item @click="changeRole(v._id)" :key="k">
                      <v-list-item-title>{{v.name || "NA"}}</v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </perfect-scrollbar>
            </v-menu>
            <span v-else>{{(rolelist[selectedrole] || {}).name || "NA"}}</span>
          </div>
        </div>
        <div v-if="showrole"><lb-divider type="vertical"></lb-divider></div>
        <div class="text-center px-4 py-2 hoverable" v-if="showyear">
          <div class="grey--text text--darken-1 line-1">Year</div>
          <div class="font-weight-bold">
            <v-menu offset-y v-if="Object.keys(yearlist).length > 1" open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <div class="filter-hide cursor-pointer" v-bind="attrs" v-on="on">{{(yearlist[selectedyear] || {}).name}}<v-icon class="">mdi-chevron-down</v-icon></div>
              </template>
              <perfect-scrollbar style="max-height: 300px">
                <v-list dense class="pa-0 border-left-default">
                  <template v-for="(v,k) in Object.values(yearlist).reverse((a,b) => (a || 100) - (b || 100))">
                    <v-list-item @click="changeYear(v._id)" :key="k">
                      <v-list-item-title>{{v.name || "NA"}}</v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </perfect-scrollbar>
            </v-menu>
            <span v-else>{{(yearlist[selectedyear] || {}).name || "NA"}}</span>
          </div>
        </div>
        <div v-if="showyear"><lb-divider type="vertical"></lb-divider></div>
        <div class="text-center px-4 py-2 hoverable" v-if="showperiod">
          <div class="grey--text text--darken-1 line-1">Period</div>
          <div class="font-weight-bold">
            <span v-if="selectedperiod">
              <v-menu offset-y v-if="Object.keys(periodlist).length > 1" open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <div class="filter-hide cursor-pointer" v-bind="attrs" v-on="on">{{(periodlist[selectedperiod] || {}).name}}<v-icon class="">mdi-chevron-down</v-icon></div>
                </template>
                <perfect-scrollbar style="max-height: 300px">
                  <v-list dense class="pa-0 border-left-default">
                    <template v-for="(v,k) in Object.values(periodlist).reverse((a,b) => (a.number || 100) - (b.number || 100))">
                      <v-list-item @click="changePeriod(v._id)" :key="k">
                        <v-list-item-title>{{v.name || "NA"}}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </perfect-scrollbar>
              </v-menu>
              <span v-else>{{(periodlist[selectedperiod] || {}).name || "NA"}}</span>
            </span>
            <span v-else>NA</span>
          </div>
        </div>
        <div v-if="showperiod"><lb-divider type="vertical"></lb-divider></div>
        <div class="text-center px-4 py-2 hoverable" v-if="shownetwork">
          <div class="grey--text text--darken-1 line-1">Network</div>
          <div class="font-weight-bold">
            <span v-if="selectednetwork">
              <v-menu offset-y v-if="Object.keys(networklist).length > 1" open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <div class="filter-hide cursor-pointer" v-bind="attrs" v-on="on">{{(networklist[selectednetwork] || {}).name}}<v-icon class="ml-1">mdi-chevron-down</v-icon></div>
                </template>
                <div>
                  <div @click.stop=";" class="white pa-2">
                    <lb-string v-model="networksearch" hidedetails label="Search"></lb-string>
                  </div>
                  <perfect-scrollbar style="max-height: 300px">
                    <v-list dense class="pa-0 border-left-default" open-on-hover>
                      <v-list-item @click="changeNetwork('all')" v-if="topnetworklist.length > 0 && selectednetwork !== 'all'">
                        <v-list-item-title class="">All</v-list-item-title>
                      </v-list-item>
                      <template v-for="(v,k) in topnetworklist.slice(0, 25)">
                        <v-list-item @click="changeNetwork(v._id)" :key="k">
                          <v-list-item-title :class="`${v.mark==='yes'?'font-weight-bold':(v.mark==='no'?'grey--text text--darken-1':'')}`">{{v.name || "NA"}}</v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-list-item v-if="topnetworklist.length > 25">
                        <v-list-item-title class="grey--text caption">{{topnetworklist.length - 25}} more</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </perfect-scrollbar>
                </div>
              </v-menu>
              <span v-else>{{(networklist[selectednetwork] || {}).name || "NA"}}</span>
            </span>
            <span v-else>NA</span>
          </div>
        </div>
        <div v-if="shownetwork"><lb-divider type="vertical"></lb-divider></div>
        <div class="text-center px-4 py-2 hoverable" v-if="showregion">
          <div class="grey--text text--darken-1 line-1">Region</div>
          <div class="font-weight-bold">
            <span v-if="selectedregion">
              <v-menu offset-y v-if="Object.keys(regionlist).length > 1" open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <div class="filter-hide cursor-pointer" v-bind="attrs" v-on="on">{{(regionlist[selectedregion] || {}).name}}<v-icon class="ml-1">mdi-chevron-down</v-icon></div>
                </template>
                <div>
                  <div @click.stop=";" class="white pa-2">
                    <lb-string v-model="regionsearch" hidedetails label="Search"></lb-string>
                  </div>
                  <perfect-scrollbar style="max-height: 300px">
                    <v-list dense class="pa-0 border-left-default">
                      <v-list-item @click="changeRegion('all')" v-if="topregionlist.length > 0 && selectedregion !== 'all'">
                        <v-list-item-title class="">All</v-list-item-title>
                      </v-list-item>
                      <template v-for="(v,k) in topregionlist.slice(0,25)">
                        <v-list-item @click="changeRegion(v._id)" :key="k">
                          <v-list-item-title :class="`${v.mark==='yes'?'font-weight-bold':(v.mark==='no'?'grey--text text--darken-1':'')}`">{{v.name || "NA"}}</v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-list-item v-if="topregionlist.length > 25">
                        <v-list-item-title class="grey--text caption">{{topregionlist.length - 25}} more</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </perfect-scrollbar>
                </div>
              </v-menu>
              <span v-else>{{(regionlist[selectedregion] || {}).name || "NA"}}</span>
            </span>
            <span v-else>NA</span>
          </div>
        </div>
        <div v-if="showregion"><lb-divider type="vertical"></lb-divider></div>
        <div class="text-center px-4 py-2 hoverable" v-if="showcountry">
          <div class="grey--text text--darken-1 line-1">Country</div>
          <div class="font-weight-bold">
            <span v-if="selectedcountry">
              <v-menu offset-y v-if="Object.keys(countrylist).length > 1" open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="filter-hide cursor-pointer">{{(countrylist[selectedcountry] || {}).name}}<v-icon class="ml-1">mdi-chevron-down</v-icon></div>
                </template>
                <div>
                  <div @click.stop="" class="white pa-2">
                    <lb-string v-model="countrysearch" hidedetails label="Search"></lb-string>
                  </div>
                  <perfect-scrollbar style="max-height: 300px">
                    <v-list dense class="pa-0 border-left-default">
                      <v-list-item @click="changeCountry('all')" v-if="topcountrylist.length > 0 && selectedcountry !== 'all'">
                        <v-list-item-title class="">All</v-list-item-title>
                      </v-list-item>
                      <template v-for="(v,k) in topcountrylist.slice(0,25)">
                        <v-list-item @click="changeCountry(v._id)" :key="k">
                          <v-list-item-title :class="`${v.mark==='yes'?'font-weight-bold':(v.mark==='no'?'grey--text text--darken-1':'')}`">{{v.name || "NA"}}</v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-list-item v-if="topcountrylist.length > 25">
                        <v-list-item-title class="grey--text caption">{{topcountrylist.length - 25}} more</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </perfect-scrollbar>
                </div>
              </v-menu>
              <span v-else>{{(countrylist[selectedcountry] || {}).name || "NA"}}</span>
            </span>
            <span v-else>NA</span>
          </div>
        </div>
        <div v-if="showcountry"><lb-divider type="vertical"></lb-divider></div>
        <div class="text-center px-4 py-2 hoverable" v-if="showreportingunit">
          <div class="grey--text text--darken-1 line-1">Reporting Unit</div>
          <div class="font-weight-bold">
            <span v-if="selectedreportingunit">
              <v-menu offset-y v-if="Object.keys(reportingunitlist).length > 1" open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <div class="filter-hide cursor-pointer" v-bind="attrs" v-on="on">{{(reportingunitlist[selectedreportingunit] || {}).name}}<v-icon class="ml-1">mdi-chevron-down</v-icon></div>
                </template>
                <div>
                  <div @click.stop=";" class="white pa-2">
                    <lb-string v-model="reportingunitsearch" hidedetails label="Search"></lb-string>
                  </div>
                  <perfect-scrollbar style="max-height: 300px">
                    <v-list dense class="pa-0 border-left-default">
                      <v-list-item @click="changeReportingUnit('all')" v-if="topreportinunitlist.length > 0 && selectedreportingunit !== 'all'">
                        <v-list-item-title class="">All</v-list-item-title>
                      </v-list-item>
                      <template v-for="(v,k) in topreportinunitlist.slice(0,25)">
                        <v-list-item @click="changeReportingUnit(v._id)" :key="k">
                          <v-list-item-title :class="`${v.mark==='yes'?'font-weight-bold':(v.mark==='no'?'grey--text text--darken-1':'')}`">{{v.name || "NA"}}</v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-list-item v-if="topreportinunitlist.length > 25">
                        <v-list-item-title class="grey--text caption">{{topreportinunitlist.length - 25}} more</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </perfect-scrollbar>  
                </div>
              </v-menu>
              <span v-else>{{(reportingunitlist[selectedreportingunit] || {}).name || "NA"}}</span>
            </span>
            <span v-else>NA</span>
          </div>
        </div>
        <div><lb-divider type="vertical"></lb-divider></div>
        <div class="text-center py-2 px-1 hoverable" v-if="showreportingunit">
          <div>
            <v-tooltip bottom content-class="tooltip-bottom">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click.stop="setValue('reload', true)"><v-icon>mdi-close</v-icon></v-btn>
              </template>
              <span>Reset filters</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'commentaryworking',
  data: function(){
    return {
      loading: false,
      hierarchyData: {},
      selectedrole: "",
      role: [],
      rolelist: {},
      selectedyear: "",
      year: [],
      possibleyear: [],
      yearlist: {},
      selectedperiod: "",
      period: [],
      possibleperiod: [],
      periodlist: {},
      allperiod: [],
      selectednetwork: "",
      networksearch: "",
      network: [],
      networklist: {},
      newnetworklist: {},
      selectedregion: "",
      regionsearch: "",
      region: [],
      possibleregion: [],
      regionlist: {},
      newregionlist: {},
      selectedcountry: "",
      countrysearch: "",
      country: [],
      possiblecountry: [],
      countrylist: {},
      newcountrylist: {},
      selectedreportingunit: "",
      reportingunitsearch: "",
      reportingunit: [],
      possiblereportingunit: [],
      reportingunitlist: {},
      newreportingunitlist: {},
      appname: "",
      alldata: {"_id": "all", "name": "All"},
      isReportSelected:false,
      previousFilterState: { 'selectedperiod': 'all', 'selectednetwork': 'all', 'selectedreportingunit': 'all', 'selectedregion': 'all', 'selectedcountry': 'all', 'emit': true }
    }
  },
  props: {
    cycletype: {
      type: String,
      default: ""
    },
    showrole: {
      type: Boolean,
      default: true
    },
    showyear: {
      type: Boolean,
      default: true
    },
    showperiod: {
      type: Boolean,
      default: true
    },
    shownetwork: {
      type: Boolean,
      default: true
    },
    showregion: {
      type: Boolean,
      default: true
    },
    showcountry: {
      type: Boolean,
      default: true
    },
    showreportingunit: {
      type: Boolean,
      default: true
    },
    asadmin: {
      type: Boolean,
      default: false
    }
  },
  activated() {
  },
  mounted() {
    this.appname = "m_" + (this.$route.path || "").split("/")[1] || "";
    this.refreshData();
  },
  methods: {
    refreshData() {
      let existingfilter = {};
      if(this.cycletype === "Actual") existingfilter = (this.$store.state[this.appname] || {}).actualfilter || {}
      else if(this.cycletype === "Forecast") existingfilter = (this.$store.state[this.appname] || {}).forecastfilter || {}
      // console.log(existingfilter);
      this.selectedrole = existingfilter.selectedrole || '';
      this.selectedyear = existingfilter.selectedyear || this.year[0]?._id || '';
      this.selectednetwork = existingfilter.selectednetwork || "";
      this.selectedperiod = existingfilter.selectedperiod || "";
      this.selectedcountry = existingfilter.selectedcountry || "";
      this.selectedregion = existingfilter.selectedregion || "";
      this.selectedreportingunit = existingfilter.selectedreportingunit || "";
      this.getData();
    },
    setValue: function(k,value) {
        this[k] = value;
        // console.log("setting", k, value);
        //let stopemitflag = true;
        if(k === 'reload') {
          this.changeReportingUnit('all');
          this.changeCountry('all');
          this.changeRegion('all');
          this.changeNetwork('all');
          this.changeRole('all');

          this.selectedrole = "all";
          this.refreshData();
        }
        else if(k === 'selectedrole') this.changeRole(value);
        else if(k === 'selectedyear') this.changeYear(value);
        else if(k === 'selectedperiod') this.changePeriod(value);
        else if(k === 'selectednetwork') this.changeNetwork(value);
        else if(k === 'selectedregion') this.changeRegion(value);
        else if(k === 'selectedcountry') this.changeCountry(value);
        else if(k === 'selectedreportingunit') this.changeReportingUnit(value);
        else this.stopemitflag = true;
        this.setDropdowns(this.stopemitflag);
    },
    setLocalFilterValues(){
      let localcopy = {
        selectedrole: this.selectedrole,
        selectedyear: this.selectedyear,
        selectedperiod: this.selectedperiod,
        selectednetwork: this.selectednetwork,
        selectedregion: this.selectedregion,
        selectedcountry: this.selectedcountry,
        selectedreportingunit: this.selectedreportingunit,
      }
      // console.log(localcopy);
      if(this.cycletype === "Actual") this.$store.commit(this.appname + "/setActualFilters", localcopy);
      else if(this.cycletype === "Forecast") this.$store.commit(this.appname + "/setForecastFilters", localcopy);
    },
    sendEvent: function(k,value, reload=false) {
      this.$emit('newdata', {key: k, value: value, reload: reload});
    },
    getData(manualRefresh=false) {
      let fltrdata = {};
      if(this.cycletype === "Actual") fltrdata = (this.$store.state[this.appname] || {}).actualhierarchydata || {};
      else if(this.cycletype === "Forecast") fltrdata = (this.$store.state[this.appname] || {}).forecasthierarchydata || {};
      if(Object.keys(fltrdata).length === 0 || manualRefresh){
        this.loading = true;
        this.$emit('newdata', {key: 'loading', value: true});
        //this.axios.post("/v2/varianceanalysis/analysis/getaccesshierarchyV2", {filter: {cycletype: this.cycletype, role: this.selectedrole, asadmin: this.asadmin}}).then(dt => {
        let filter={"filter":{"cycletype":this.cycletype,"type":"ru","role":"all","network":"all","region":"all","country":"all","reportingunit":"all"}}
        if(manualRefresh){
          filter.filter['sync_type']="manual"
        }
          this.axios.post("/v2/varianceanalysis/analysis/getaccesshierarchyV2", filter).then(dt => {
          if(dt.data.status === "success") {
            this.hierarchyData = dt.data.data[0] || dt.data.data;
            if(this.cycletype === "Actual") this.$store.commit(this.appname + "/setActualFiltersHieararchy", this.hierarchyData);
            else if(this.cycletype === "Forecast") this.$store.commit(this.appname + "/setForecastFiltersHieararchy", this.hierarchyData);
            if((this.hierarchyData.reportingunitlist || []).length > 0) this.populateAccessHierarchy();
            this.$emit('isDataSynced',{'syncCompleted':false})
          }
          else throw new Error ("Error fetching data");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.loading = false;
          this.$emit('newdata', {key: 'loading', value: false,reload:true});
        })
      }else{
        if(this.cycletype === "Actual") this.hierarchyData = (this.$store.state[this.appname] || {}).actualhierarchydata || {};
        else if(this.cycletype === "Forecast") this.hierarchyData = (this.$store.state[this.appname] || {}).forecasthierarchydata || {};
        if((this.hierarchyData.reportingunitlist || []).length > 0) this.populateAccessHierarchy();
      }
    },
    populateAccessHierarchy() {
      let data = {...this.hierarchyData};
      // console.log( this.appname,"FFF");
      // let menuitems = [];
      // let storeobj = this.$store.state[this.appname] || {};
      // console.log(storeobj);
      this.$store.commit(this.appname + "/setFilters", this.hierarchyData);
      let rulist = data.reportingunitlist;
      let usedid = [];

      // set role
      this.role = [];
      usedid = [];
      this.rolelist = {};
      for (let i = 0; i < (data.possibleroles || []).length; i++) {
        const el = data.possibleroles[i];
        if(usedid.indexOf(el) === -1){
          let txt = "";
          if(el === "submitter") txt = "Local Preparer";
          else if(el === "approver") txt = "Local Approver";
          else if(el === "manager") txt = "Manager";
          else if(el === "regional_approver") txt = "Regional Approver";
          else if(el === "all") txt = "All";
          // if(txt.length>0) txt = txt[0].toUpperCase() + txt.slice(1).join(""); 
          let nd = {_id: el, name: txt};
          if(txt) usedid.push(el);
          this.role.push(nd);
          this.rolelist[el] = nd;
        }
      }
      
      // set year
      this.year = [];
      usedid = [];
      this.yearlist = {};
      data.yearlist = data.yearlist.reverse((a,b) => a-b);
      // console.log(data.yearlist);
      for (let i = 0; i < (data.yearlist || []).length; i++) {
        const el = data.yearlist[i];
        if(usedid.indexOf(el) === -1 && el._id !== "all"){
          let nd = {_id: el, name: el}
          usedid.push(el);
          this.year.push(nd)
          this.yearlist[el] = nd;
        }
      }
      this.year = this.year.sort((a,b) =>  b.name - a.name);
      console.log(this.year);
      if(!this.selectedyear) this.selectedyear = this.year[0]?._id;

      // set period
      //usedid = [];
      this.allperiod = data.periodlist || [];
      this.recalculatePeriod();
      // set network
      this.network = data.networklist || [];
      this.networklist = {};
      for (const i of this.network) {
        this.networklist[i._id] = i;
      }
      if(this.network.length > 1){
        this.network.unshift({...this.alldata});
        this.networklist[this.alldata._id] = {...this.alldata};
      }
      this.newnetworklist = this.networklist;

      // set region
      this.region = data.regionlist || [];
      this.regionlist = {};
      for (const i of this.region) {
        this.regionlist[i._id] = i;
      }
      if(this.region.length > 1){
        this.region.unshift({...this.alldata});
        this.regionlist[this.alldata._id] = {...this.alldata};
      }
      this.newregionlist = this.regionlist;

      // set country
      this.country = data.countrylist || [];
      this.countrylist = {};
      for (const i of this.country) {
         this.countrylist[i._id] = i;
      }
      if(this.country.length > 1){
        this.country.unshift({...this.alldata});
        this.countrylist[this.alldata._id] = {...this.alldata};
      }
      this.newcountrylist = this.countrylist;

      // set reportingunit
      usedid = [];
      this.reportingunit = [];
      this.reportingunitlist = {};
      for (const i of rulist) {
        if(usedid.indexOf(i._id) === -1){
          let nd = {_id: i._id, name: i.code + " - " + i.name, cycle: i.cycle}
          usedid.push(i._id);
          this.reportingunit.push(nd)
          this.reportingunitlist[i._id] = nd;
        }
      }

      for (const i of this.reportingunit) {
         this.reportingunitlist[i._id] = i;
      }
      if(this.reportingunit.length > 0){
        this.reportingunit.unshift({...this.alldata});
        this.reportingunitlist[this.alldata._id] = {...this.alldata};
      }
      this.newreportingunitlist = this.reportingunitlist;

      this.setDropdowns();
    },
    setDropdowns(stopemitflag = false){
      // set defaults
      if(this.role.length === 1) this.selectedrole = this.role[0]._id;
      else this.selectedrole = this.selectedrole || "all";
      // this.selectedyear = this.year[0]._id;
      if(this.period.length === 1) this.selectedperiod = this.period[0]._id;
      else this.selectedperiod = this.selectedperiod || "all" ;
      if(this.network.length === 1) this.selectednetwork = this.network[0]._id;
      else this.selectednetwork = this.selectednetwork || "all";
      if(this.region.length === 1) this.selectedregion = this.region[0]._id;
      else this.selectedregion = this.selectedregion || "all";
      if(this.country.length === 1) this.selectedcountry = this.country[0]._id;
      else this.selectedcountry = this.selectedcountry || "all";
      // if(this.reportingunit.length === 1) this.selectedreportingunit = this.reportingunit[0]._id;
      this.selectedreportingunit = this.selectedreportingunit || "all";

      // push all the info
      if(!stopemitflag){
        this.sendEvent("role", this.role);
        this.sendEvent("rolelist", this.rolelist);
        this.sendEvent("selectedrole", this.selectedrole);
        this.sendEvent("year", this.year);
        this.sendEvent("yearlist", this.yearlist);
        this.sendEvent("selectedyear", this.selectedyear);
        this.sendEvent("period", this.period);
        this.sendEvent("periodlist", this.periodlist);
        this.sendEvent("selectedperiod", this.selectedperiod);
        this.sendEvent("network", this.network);
        this.sendEvent("networklist", this.networklist);
        this.sendEvent("selectednetwork", this.selectednetwork);
        this.sendEvent("region", this.region);
        this.sendEvent("regionlist", this.regionlist);
        this.sendEvent("selectedregion", this.selectedregion);
        this.sendEvent("country", this.country);
        this.sendEvent("countrylist", this.countrylist);
        this.sendEvent("selectedcountry", this.selectedcountry);
        this.sendEvent("reportingunit", this.reportingunit);
        this.sendEvent("reportingunitlist", this.reportingunitlist);
        this.sendEvent("selectedreportingunit", this.selectedreportingunit,true);
        this.$emit('newdata', {key: 'loading', value: false});
      }
      this.setLocalFilterValues();
    },
    checkDropdown(field, key, oldlist, newlist, from=[], preselect=true){
      let data = (this.hierarchyData || {}).reportingunitlist;
      //get possible network
      let count = 0;
      let possibleids = [];

      let byruids = [];
      if(this.selectedreportingunit && this.selectedreportingunit !== "all" && from.indexOf("ru") > -1){
        count++;
        byruids = this.getSelectableIds(field, "_id", this.selectedreportingunit, data);
      }
      let byregionids = [];
      if(this.selectedregion && this.selectedregion !== "all" && from.indexOf("region") > -1){
        count++;
        byregionids = this.getSelectableIds(field, "region", this.selectedregion, data);
      }
      let bycountryids = [];
      if(this.selectedcountry && this.selectedcountry !== "all" && from.indexOf("country") > -1){
        count++;
        bycountryids = this.getSelectableIds(field, "country", this.selectedcountry, data);
      }
      let bynetworkids = [];
      if(this.selectednetwork && this.selectednetwork !== "all" && from.indexOf("network") > -1){
        count++;
        // console.log(field, "network", this.selectednetwork, data)
        bynetworkids = this.getSelectableIds(field, "network", this.selectednetwork, data);
        // console.log(bynetworkids);
      }
      let newids = [...byruids, ...byregionids, ...bycountryids, ...bynetworkids];
      // console.log(byruids, byregionids, bycountryids, bynetworkids);
      if(count > 0){
        let counter = {};

      for (const i of newids) {
        if (counter[i]) {
                counter[i] += 1;
            } else {
                counter[i] = 1;
            }
      }
        for (const k in counter) {
          if (Object.hasOwnProperty.call(counter, k)) {
            const el = counter[k];
            if(el === count) possibleids.push(k);            
          }
        }
        if(possibleids.length > 0) {
          // console.log("possible selection", possibleids, key, preselect);
          if(possibleids.length === 1) {if(preselect) this[key] = possibleids[0];}
          else if(possibleids.indexOf(this[key]) === -1) this[key] = "all";
          if(possibleids.length < Object.keys(this[oldlist]).length-1){
            let first = {};
            let last = {};
            for (const k in this[oldlist]) {
              if (Object.hasOwnProperty.call(this[oldlist], k)) {
                const el = {...this[oldlist][k]};
                if(possibleids.indexOf(el._id) > -1) {
                  el.mark = "yes";
                  first[k] = el;
                }else {
                  el.mark = "no";
                  last[k] = el;
                }            
              }
            }
            this[newlist] = {...first, ...last};
          }
        }else this[key] = "all"
      }else {
        if(from.indexOf(field) === -1) this[newlist] = this[oldlist];
      }
    },
    getSelectableIds(field, searchcolumn, value, data) {
      let uniqid = [];
        // console.log("------", data);
      for (const i of data) {
        if(i[searchcolumn] === value){
          // console.log("------", searchcolumn, i[searchcolumn], value, field, i);
          if(uniqid.indexOf(i[field]) === -1) uniqid.push(i[field])
        }
      }
      return uniqid;
    },
    changeRole(d) {
      this.selectedrole = d;
      this.selectedyear = "";
      this.selectedperiod = "";
      this.selectednetwork = "";
      this.selectedregion = "";
      this.selectedcountry = "";
      this.selectedreportingunit = "";
      this.setLocalFilterValues();
      this.refreshData();
    },
    changeYear(d) {
      this.selectedyear = d;
      this.recalculatePeriod();
      this.selectedperiod = this.period[1]?._id || this.period[0]?._id || 'all';
      this.selectednetwork = "";
      this.selectedregion = "";
      this.selectedcountry = "";
      this.selectedreportingunit = "";
      this.isReportSelected = true;
      
      this.setDropdowns();
    },
    changePeriod(d) {
      this.selectedperiod = d;
      if(this.selectedreportingunit === "all"){
        this.selectednetwork = "all";
        this.selectedregion = "all";
        this.selectedcountry = "all";
        this.selectedreportingunit = "all";
        this.isReportSelected = true;
      }
      this.setDropdowns();
    },
    changeNetwork(d) {
      this.selectednetwork = d;
      this.selectedregion = "all";
      this.selectedcountry = "all";
      this.selectedreportingunit = "all";
      this.regionsearch = "";
      this.countrysearch = "";
      this.reportingunitsearch = "";
      this.isReportSelected=true;
      this.setNetworkDropdown(['network']);
      this.setCountryDropdown(['network']);
      if(this.selectedcountry === "all") this.setRegionDropdown(['network']);
      else {
        this.setRegionDropdown(['network', 'country']);
      }
      this.setCountryDropdown(['network', 'region']);
      this.setReportingUnitDropdown(['network', 'region', 'country']);
      this.setDropdowns();
    },
    changeRegion(d) {
      this.selectedregion = d;
      this.countrysearch = "";
      this.networksearch = "";
      this.reportingunitsearch = "";
      this.selectedreportingunit = "all";
      this.selectedcountry = "all";
      if(this.selectedregion === "all"){
        if(this.selectednetwork !== "all"){
          this.setNetworkDropdown(['network'], false);
          this.setCountryDropdown(['network'], false);
          this.setReportingUnitDropdown(['network'], false);
        }
      }else{
        if(this.selectednetwork === "all"){
          this.setNetworkDropdown(['region'], false);
          this.setCountryDropdown(['region'], false);
          this.setReportingUnitDropdown(['region'], false);
        }else{
          this.setNetworkDropdown(['region']);
          this.setCountryDropdown(['region', 'network'], false);
          this.setReportingUnitDropdown(['region', 'network'], false);
        }
      }

      this.setRegionDropdown(['region']);
      this.setNetworkDropdown(['region']);
      this.setNetworkDropdown(['region', 'country']);
      this.setCountryDropdown(['region']);
      this.setCountryDropdown(['region', 'network']);
      this.setReportingUnitDropdown(['region', 'network', 'country']);
      this.setDropdowns();
    },
    changeCountry(d) {
      this.selectedcountry = d;
      this.countrysearch = "";
      this.networksearch = "";
      this.reportingunitsearch = "";
      this.selectedreportingunit = "all";
      this.isReportSelected=true
      if(this.selectedcountry === "all"){
        if(this.selectednetwork !== "all" && this.selectedregion !== "all"){
          this.setCountryDropdown(['network', 'region'], false);
          this.setReportingUnitDropdown(['network', 'region'], false);
          this.setNetworkDropdown(['region'], false);
          this.setRegionDropdown(['network'], false);
        }
        else if(this.selectednetwork !== "all"){
          this.setCountryDropdown(['network'], false);
          this.setReportingUnitDropdown(['network'], false);
          this.setRegionDropdown(['network'], false);
        }
        else if(this.selectedregion !== "all"){
          this.setCountryDropdown(['region'], false);
          this.setReportingUnitDropdown(['region'], false);
          this.setRegionDropdown(['region'], false);
        }
      }else{
        this.setRegionDropdown(['country']);
        this.setNetworkDropdown(['country', 'region']);
        this.setReportingUnitDropdown(['country'], false);
      }
      this.setDropdowns();
    },
    changeReportingUnit(d) {
      if(this.selectedreportingunit === "all"){
        this.$emit('isReportingunitSelected',d)
      }
      this.selectedreportingunit = d;
      this.countrysearch = "";
      this.networksearch = "";
      this.reportingunitsearch = "";
      if(this.selectedreportingunit === "all"){
        this.setCountryDropdown(['region', 'network']);
      }else {
        this.regionsearch = "";
        this.countrysearch = "";
        this.networksearch = "";
        this.setCountryDropdown(['ru']);
        this.setRegionDropdown(['ru']);
        this.setNetworkDropdown(['ru']);
      }
      this.setDropdowns();
    },
    setNetworkDropdown(from=[], preselect=true) {
      this.checkDropdown('network', 'selectednetwork', 'networklist', 'newnetworklist', from, preselect);
    },
    setRegionDropdown(from=[], preselect=true) {
      this.checkDropdown('region', 'selectedregion', 'regionlist', 'newregionlist', from, preselect);
    },
    setCountryDropdown(from=[], preselect=true) {
      this.checkDropdown('country', 'selectedcountry', 'countrylist', 'newcountrylist', from, preselect);
    },
    setReportingUnitDropdown(from=[], preselect=true) {
      this.checkDropdown('_id', 'selectedreportingunit', 'reportingunitlist', 'newreportingunitlist', from, preselect);
    },
    sortData(data){
      return data.sort((a, b) => a.name.localeCompare(b.name));
    },
    recalculatePeriod(){
      let tperiodlist = {};
      for (const i of this.allperiod) {
        if(this.selectedyear){
          if(i.year === this.selectedyear) tperiodlist[i._id] = {name: i.month, _id: i._id, number: i.number};
        }else tperiodlist[i._id] = {name: i.month, _id: i._id, number: i.number};
      }
      let tperiod = [];
      let hasselected = false;
      tperiodlist = this.sortObjectsByNumberDescending(tperiodlist, 'number');
      for (const k in tperiodlist) {
        if (Object.hasOwnProperty.call(tperiodlist, k)) {
          const el = tperiodlist[k];
          if(el._id === this.selectedperiod) hasselected = true;
          tperiod.push({_id: el._id, name: el.name, k: el.number});
        }
      }
      this.periodlist = this.reverseObject(tperiodlist);
      if(tperiod.length > 1){
        tperiod.unshift({...this.alldata});
        this.periodlist[this.alldata._id] = {...this.alldata};
      }
      this.period = tperiod
      console.log(tperiodlist)
      console.log(tperiod)
      if(!hasselected) this.selectedperiod = this.period[1]?._id || this.period[0]?._id || 'all';
    },
    sortObjectsByNumberDescending(obj,sortKey = 'number'){
      const entries = Object.entries(obj);
      const entriesWithNumber = entries.filter(([, value]) => Object.prototype.hasOwnProperty.call(value, sortKey));
      entriesWithNumber.sort((a, b) => b[1].number - a[1].number);
      const sortedObj = {};
      for (const [key, value] of entriesWithNumber) {
        sortedObj[key] = value;
      }
      for (const [key, value] of entries) {
        if (!Object.prototype.hasOwnProperty.call(value, sortKey)) {
          sortedObj[key] = value;
        }
      }
      return sortedObj;
    },
    reverseObject(obj){
        const entries = Object.entries(obj);
        const reversedEntries = entries.reverse();
        const reversedObj = {};
        for (const [key, value] of reversedEntries) {
            reversedObj[key] = value;
        }
        return reversedObj;
    },
    setFilterItem(d){
      // this.selectedperiod = d.selectedperiod;
      // this.selectedreportingunit = d.selectedreportingunit
      // this.selectedcountry = d.selectedcountry;
      // this.selectedregion = d.selectedregion
      // this.selectednetwork = d.selectednetwork;
      this.selectedperiod = d.selectedperiod || this.period[1]._id || this.period[0]._id || "all";
      this.selectedreportingunit = d.selectedreportingunit || "all";
      this.selectedcountry = d.selectedcountry || "all";
      this.selectedregion = d.selectedregion || "all";
      this.selectednetwork = d.selectednetwork || "all";
      if(d.emit){
        this.sendEvent("selectedperiod", this.selectedperiod);
        this.sendEvent("selectednetwork", this.selectednetwork);
        this.sendEvent("selectedregion", this.selectedregion);
        this.sendEvent("selectedcountry", this.selectedcountry);
        this.sendEvent("reportingunit", this.reportingunit);
        this.sendEvent("reportingunitlist", this.reportingunitlist);
        this.sendEvent("selectedreportingunit", this.selectedreportingunit, true);
      }
    },
    setPreviousFilterState(d){
      if (this.cycletype === "Actual") this.$store.commit(this.appname + "/setPreviousActualFilterState", d);
      else if (this.cycletype === "Forecast") this.$store.commit(this.appname + "/setPreviousForecastFilters", d);
        this.previousFilterState =  d;
    },
  },
  computed: {
    topnetworklist(){
      let rdt = [];
      for (const k in this.newnetworklist) {
        if (Object.hasOwnProperty.call(this.newnetworklist, k)) {
          const el = this.newnetworklist[k];
          if(el.name.replaceAll(' ', '').toLowerCase().indexOf((this.networksearch || '').replaceAll(' ', '').toLowerCase()) >-1 && el._id !== "all") rdt.push(el);
        }
      }
      rdt = rdt.sort((a,b) => a.name > b.name);
      rdt = rdt.sort((a,b) => (a.mark === 'yes' && b.mark === 'no')?1:0);
      return rdt;
    },
    topregionlist(){
      let rdt = [];
      for (const k in this.newregionlist) {
        if (Object.hasOwnProperty.call(this.newregionlist, k)) {
          const el = this.newregionlist[k];
          if(el.name.replaceAll(' ', '').toLowerCase().indexOf((this.regionsearch || '').replaceAll(' ', '').toLowerCase()) >-1 && el._id !== "all") rdt.push(el);
        }
      }
      rdt = rdt.sort((a,b) => a.name > b.name);
      rdt = rdt.sort((a,b) => (a.mark === 'yes' && b.mark === 'no')?1:0);
      return rdt;
    },
    topcountrylist(){
      let rdt = [];
      for (const k in this.newcountrylist) {
        if (Object.hasOwnProperty.call(this.newcountrylist, k)) {
          const el = this.newcountrylist[k];
          if(el.name.replaceAll(' ', '').toLowerCase().indexOf((this.countrysearch || '').replaceAll(' ', '').toLowerCase()) >-1 && el._id !== "all") rdt.push(el);
        }
      }
      rdt = rdt.sort((a,b) => a.name > b.name);
      rdt = rdt.sort((a,b) => (a.mark === 'yes' && b.mark === 'no')?1:0);
      return rdt;
    },
    topreportinunitlist(){
      let rdt = [];
      for (const k in this.newreportingunitlist) {
        if (Object.hasOwnProperty.call(this.newreportingunitlist, k)) {
          const el = this.newreportingunitlist[k];
          if(el.name.replaceAll(' ', '').toLowerCase().indexOf((this.reportingunitsearch || '').replaceAll(' ', '').toLowerCase()) >-1 && el._id !== "all") rdt.push(el);
        }
      }
      rdt = rdt.sort((a,b) => a.name > b.name);
      rdt = rdt.sort((a,b) => (a.mark === 'yes' && b.mark === 'no')?1:0);
      return rdt;
    }
  },
  watch: {
    selectedyear: function(){
      this.recalculatePeriod();
    }
  }
}
</script>

